import React, { useState } from 'react'
import NavBar, {NavItem, NavLink} from '../../shared/navbar'
import {MainMenuWrap} from './mainmenu.stc'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
 


export const MainMenu = ({menuData, ...props}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const menuarr = menuData; 
    return (
        <MainMenuWrap {...props}>  
            <NavBar>
            
                {menuarr.map((menu, i) => { 
                    return (
                        <NavItem key={`mainmenu-${i}`}>
                            <NavLink path={menu.node.path}>{menu.node.title}</NavLink>
                        </NavItem>
                        
                    )
                })} 

            </NavBar>
            <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle nav caret>
                <NavLink style={{color:'black', marginLeft: '15px'}}>Seasons</NavLink> 
                </DropdownToggle>
                <DropdownMenu>
                <NavLink path={"/centralia"}><DropdownItem >Centralia</DropdownItem></NavLink>
                    <DropdownItem divider />
                    <NavLink path={"/celestial"}><DropdownItem >Celestial Star</DropdownItem></NavLink>
                    <DropdownItem divider />
                    <NavLink path={"/charlie"}><DropdownItem >Charlie Saves Christmas</DropdownItem></NavLink>
                    <DropdownItem divider />
                    <NavLink path={"/cassie"}><DropdownItem >Cassie and The Spectral Shade</DropdownItem></NavLink>
                    <DropdownItem divider />
       
                </DropdownMenu>
                </Dropdown> 
   
        </MainMenuWrap>
    )
} 