import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useStaticQuery, graphql } from "gatsby"
import LogoImg from '../../../assets/img/WebFest_Winner.png'
import List, { ListItem } from '../../../components/shared/list'
import Text from '../../../components/shared/text'
import Anchor from '../../../components/shared/anchor'
import Social from '../../../components/socials/layout-one'
import Mailchimp from '../../../components/forms/mailchimp'
import SectionTitle from '../../../components/shared/section-title'
import {
    FooterWrap,
    FooterTop,
    FooterBottom,
    FooterWidget,
    AddressWidget
} from './footer.stc'

const Footer = (props) => {
    const FooterData = useStaticQuery(graphql`
        query FooterDataQuery {
            site {
                siteMetadata {
                    contact {
                        social {
                            facebook
                            instagram
                            linkedin
                            twitter
                            youtube
                        }
                    }
                }
            }
        }      
    `)
    const { social } = FooterData.site.siteMetadata.contact;
    const {
        footerMenuOne,
        address: { addressAnchor, addressText },
        socialStyle,
        footerMenuTwo,
        copyright,
        widgetStyle: { logoWidget, bookWidget, socialWidget, menuTwoWidget, appleWidget } } = props
    return (
        <FooterWrap>
            <Container>
                <FooterTop>
                    <Row>
                        <Col md={{ size: 3 }} lg={{ size: 2}}>
                            <FooterWidget {...logoWidget}>
                                <img src={LogoImg} alt="Footer Logo" />
                                
                                <FooterWidget {...appleWidget}></FooterWidget>
                                <List {...footerMenuOne}>
                                    <ListItem><Text>Best Director</Text></ListItem>
                                    <ListItem><Text>Outstanding Fantasy</Text></ListItem>
                                    <ListItem><Text>Best Writing Nominee</Text></ListItem>
                                    
                                </List>
                           

                            </FooterWidget>

                           
                          
                        </Col>

                        <Col md={{ size: 3, offset: 1 }} lg={{ size: 3, offset: 2 }}>
                            
                            <FooterWidget {...logoWidget}>
                            
                                <AddressWidget>
                                <a  href="https://www.amazon.com/gp/offer-listing/0578991055/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0578991055&linkCode=am2&tag=lukkygo19-20&linkId=8fad715bff289c758558876169b0573b" ><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0578991055&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=lukkygo19-20" alt="Purchase Charlie Saves Christmas" /></a>
                                </AddressWidget>
                                <FooterWidget {...appleWidget}></FooterWidget>
                                <Anchor path="https://www.amazon.com/gp/offer-listing/0578991055/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0578991055&linkCode=am2&tag=lukkygo19-20&linkId=8fad715bff289c758558876169b0573b">Buy Charlie Saves Christmas</Anchor>
                             
                            </FooterWidget>
                        </Col>
                        <Col md={{ size: 4, offset: 1 }} lg={{ size: 3, offset: 1 }}>

                            <FooterWidget {...appleWidget}>
                                <AddressWidget>
                                <a href="https://podcasts.apple.com/us/podcast/the-happygolukky-podcast/id1462599356?itsct=podcast_box&amp;itscg=30200" > <img src="https://tools.applemediaservices.com/api/badges/listen-on-apple-podcasts/badge/en-US?size=250x83&amp;releaseDate=1603380780&h=b657a7377b05d729ba8cbc277a06119e" alt="Listen on Apple Podcasts" /> </a>
                                </AddressWidget>
                                
                            </FooterWidget>
                            <Row />
                            <FooterWidget {...appleWidget}>
                                
                                <AddressWidget>
                                <a  href="https://music.apple.com/us/artist/daniel-m-nichols/1522705018?itsct=music_box&amp;itscg=30200&amp;ct=artists_daniel_m_nichols&amp;app=music&amp;ls=1" ><img src="https://tools.applemediaservices.com/api/badges/listen-on-apple-music/badge/en-US?size=250x83&h=da9d14d7270c586d62d1f06a46ce261a" alt="Listen on Apple Music" /></a>
                                </AddressWidget>
                            </FooterWidget>

                            {<iframe src="https://open.spotify.com/follow/1/?uri=spotify:artist:1E2YNdAkUkN8EJVdycRhPu?si=1NtmPHhRQlibt46DxqaaYQ&size=detail&theme=light&show-count=0" width="300" height="56" scrolling="no" frameborder="0"  allowtransparency="true"></iframe>}
                            <FooterWidget {...appleWidget}></FooterWidget>
                            <List {...footerMenuOne}>

                                    <ListItem><Anchor path="https://books2read.com/charliesaveschristmas">Purchase eBooks</Anchor></ListItem>

                                    <ListItem><Anchor path="https://shop.spreadshirt.com/lukky-go/">Purchase Merch</Anchor></ListItem>
                                    
                                    
                                </List>
                          

                        </Col>
                      
                        
                    
                    </Row>
                </FooterTop>
                <FooterBottom>
                    <Row className="align-items-end">
                        <Col md={3} xl={3}>
                            <FooterWidget {...socialWidget}>
                                <Social social={social} {...socialStyle} />
                            </FooterWidget>
                        </Col>
                        <Col md={{ size: 3, offset: 1 }} lg={{ size: 2, offset: 2 }} xl={{ size: 2, offset: 2 }}>
                            <FooterWidget {...menuTwoWidget}>
                            
                            </FooterWidget>
                        </Col>
                        <Col md={{ size: 4, offset: 1 }} lg={{ size: 4, offset: 1 }}>
                            <FooterWidget>
                                <Text {...copyright}>Copyright © 2020, HappyGoLukky</Text>
                            </FooterWidget>
                        </Col>
                    </Row>
                </FooterBottom>
            </Container>
        </FooterWrap>
    )
}

Footer.defaultProps = {
    footerMenuOne: {
        color: '#000000',
        pb: '14px',
        textTransform: 'uppercase',
        fontWeight: 500
    },
    widgetStyle: {
        logoWidget: {
            mb: ['33px', null, null, 0]
        },
        menuOneWidget: {
            mb: ['29px', null, null, 0]
        },
        socialWidget: {
            mb: ['34px', null, null, 0]
        },
        menuTwoWidget: {
            mb: ['35px', null, null, 0]
        },
        appleWidget: {
            mb: ['33px', '33px', '33px', '33px']
        },
        bookWidget: {
            mb: ['33px', '33px', '33px', '33px']
        }
    },
    address: {
        addressAnchor: {
            textTransform: 'uppercase',
            display: 'block',
            color: '#000000',
            fontWeight: 500,
            mb: '13px'
        },
        addressText: {
            fontWeight: 400,
            lineHeight: 2,
            color: '#000000',
        }
    },
    socialStyle: {
        pr: ['15px', null, null, '12px', '15px', '20px'],
        icon: {
            width: '18px',
            height: '18px'
        }
    },
    buttonStyle: {
        display: 'inline-block', 
        overflow: 'hidden',
        borderRadius: '13px', 
        width: '250px', 
        height: '83px',
    },
    footerMenuTwo: {
        color: '#000000',
        pb: '14px',
        fontWeight: 400
    },
    copyright: {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'borderColor',
        pt: '25px'
    }
}

export default Footer



// <SectionTitle
                           
//                            title={"Subscribe"}
//                        />
//                        <Mailchimp url={"https://happygolukky.us2.list-manage.com/subscribe/post?u=d1feeff478057f5002646a339&amp;id=20ab968ce9"} />