import React, { useState } from 'react'
import NavBar, {NavItem, NavLink} from '../../shared/navbar'
import {MobileMenuWrap} from './mobilemenu.stc'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

export const MobileMenu = ({menuData}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    return (
        <MobileMenuWrap>
            <NavBar>
                {menuData.map((menu, i) => {
                    return(
                        <NavItem 
                            key={`menu-item-${i}`} 
                            id={`menu-item-${i}`}>
                            <NavLink path={menu.node.path}>{menu.node.title}</NavLink>
                        </NavItem>
                    )
                })}
                <NavLink path={"/centralia"}><NavItem >Centralia</NavItem></NavLink>
                    
                    <NavLink path={"/celestial"}><NavItem >Celestial Star</NavItem></NavLink>
                  
                    <NavLink path={"/charlie"}><NavItem >Charlie Saves Christmas</NavItem></NavLink>
                   
                    <NavLink path={"/cassie"}><NavItem >Cassie and The Spectral Shade</NavItem></NavLink>
                   
       
            </NavBar>
                   
       
              
        </MobileMenuWrap>
    )
}
