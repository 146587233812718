import React from 'react'
import {  FiBook, FiTwitter, FiMusic } from "react-icons/fi";
import { FaApple, FaSpotify } from "react-icons/fa";
import Social, {SocialLink} from '../../shared/social';

const SocialOne = ({social, ...restProps}) => {
    const {youtube,instagram,facebook, linkedin, twitter} = social;
    return (
        <Social {...restProps}>
            {youtube && (
                <SocialLink path={youtube}>
                    <FaSpotify/>
                </SocialLink>
            )}
            {linkedin && (
                <SocialLink path={linkedin}>
                    <FaApple/>
                </SocialLink>
            )}
            {twitter && (
                <SocialLink path={twitter}>
                    <FiTwitter/>
                </SocialLink>
            )}
            {facebook && (
                <SocialLink path={facebook}>
                    <FiMusic/>
                </SocialLink>
            )}
            {instagram && (
                <SocialLink path={instagram}>
                    <FiBook/>
                </SocialLink>
            )}



        </Social>
    )
}

SocialOne.defaultProps = {
    pr: '27px'
}

export default SocialOne
