import styled, {css} from 'styled-components'

export const LogoWrap = styled.div `
    position: relative;
    z-index: 1;
    ${props => props.transparentBG && css `
        .dark-logo{
            display: none;
            height: 20px;
        }
        .white-logo{
            display: inherit;
            height: 20px;
        }
    `}
    ${props => !props.transparentBG && css `
        .dark-logo{
            display: inherit;
            height: 20px;
        }
        .white-logo{
            display: none;
            height: 20px;
        }
    `}
`;